import { template as template_c1d36f2e4ee74d34ad6341bb79114dc9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c1d36f2e4ee74d34ad6341bb79114dc9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
