import { template as template_0e2c5f46f54244f381f01fe43db6830f } from "@ember/template-compiler";
const FKLabel = template_0e2c5f46f54244f381f01fe43db6830f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
