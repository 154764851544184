import { template as template_b45b4400f434425c82eec67905e1c42f } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_b45b4400f434425c82eec67905e1c42f(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
